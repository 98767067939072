<template>
  <div>
    <div style="margin: 0 0 10px 10px">
      <el-button type="success" @click="exportExcel()"
        >将当前数据导出为表格</el-button
      >
    </div>
    <!-- 数据展示表格 -->
    <el-table
      :data="tableData"
      v-loading="loading"
      border
      stripe
      style="width: 100%"
    >
      <el-table-column prop="id" label="id" width="50"> </el-table-column>
      <el-table-column prop="userid" label="用户id" width="50">
      </el-table-column>
      <el-table-column prop="orderno" label="订单号"> </el-table-column>
      <el-table-column prop="ename" label="展会名称"> </el-table-column>
      <el-table-column prop="ebusiness" label="展商名称"> </el-table-column>
      <el-table-column prop="contact" label="联系人"> </el-table-column>
      <el-table-column prop="phone" label="联系电话"> </el-table-column>
      <el-table-column prop="ehall" label="展馆号"> </el-table-column>
      <el-table-column prop="eloc" label="展位号"> </el-table-column>
      <el-table-column prop="logistics" label="承运物流"> </el-table-column>
      <el-table-column prop="logistic_no" label="物流公司单号">
      </el-table-column>
      <el-table-column prop="cargo" label="货物信息"> </el-table-column>
      <el-table-column prop="cbm" label="货物体积"> </el-table-column>
      <el-table-column prop="weight" label="货物重量"> </el-table-column>
      <el-table-column prop="cargo_img" label="货物图片" width="120">
        <template slot-scope="scope">
          <img
            :src="scope.row.cargo_img"
            @click="checkImg(scope.row.cargo_img)"
            alt=""
            style="width: 100px; height: 100px"
          />
        </template>
      </el-table-column>
      <el-table-column prop="memo" label="备注"> </el-table-column>
      <el-table-column prop="createtime" label="创建时间">
        <template slot-scope="scope">
          <span v-if="scope.row.createtime">{{
            formatDate(scope.row.createtime)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="updatetime" label="更新时间">
        <template slot-scope="scope">
          <span v-if="scope.row.updatetime">{{
            formatDate(scope.row.updatetime)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="180">
        <template slot-scope="scope">
          <el-button type="primary" @click="openEditDialog(scope.row)"
            >修改</el-button
          >

          <el-popconfirm
            title="这是一段内容确定删除吗？"
            @confirm="delWarHouseOrders(scope.row.id)"
          >
            <el-button slot="reference" type="danger" style="margin-left: 10px"
              >删除</el-button
            >
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <el-row :gutter="20" style="margin: 0 20px 0 20px">
      <el-col :span="12" :offset="0" style="line-height: 60px">
        <span>共 {{ total }} 页</span>
        <span style="margin-left: 20px">显示页数</span>
        <el-input-number
          v-model="list"
          controls-position="right"
          @change="GetRechargeLists"
          :min="1"
          :max="100"
          style="margin-left: 10px"
        ></el-input-number>
      </el-col>
      <el-col :span="12" :offset="0">
        <div
          style="
            display: flex;
            justify-content: right;
            align-items: right;
            margin-top: 20px;
          "
        >
          <el-button
            round
            style="padding: 10px"
            @click="changePage(currentPage - 1)"
            :disabled="currentPage === 1"
          >
            <i class="el-icon-arrow-left"></i>
          </el-button>
          <div v-for="page in total" :key="page" style="margin: 0 5px">
            <div
              @click="changePage(page)"
              :class="{ 'active-page': currentPage === page }"
              class="page-button"
            >
              {{ page }}
            </div>
          </div>
          <el-button
            round
            style="padding: 10px"
            @click="changePage(currentPage + 1)"
            :disabled="currentPage === total"
          >
            <i class="el-icon-arrow-right"></i>
          </el-button>
        </div>
      </el-col>
    </el-row>
    <!-- 修改弹窗 -->
    <el-dialog
      title="修改仓库代收订单"
      :visible.sync="dialogVisible"
      width="30%"
    >
      <el-row :gutter="20">
        <el-col :span="12" :offset="0">
          <el-form>
            <el-form-item label="联系人">
              <el-input v-model="editContact"></el-input>
            </el-form-item>
            <el-form-item label="联系电话">
              <el-input v-model="editPhone"></el-input>
            </el-form-item>
            <el-form-item label="承运物流">
              <br />
              <el-select v-model="editLogistics" placeholder="请选择承运物流">
                <el-option
                  v-for="item in logistics"
                  :key="item.id"
                  :label="item.name"
                  :value="item.name"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="物流单号">
              <el-input v-model="editLogisticNo"></el-input>
            </el-form-item>
            <el-form-item label="货物信息">
              <el-input v-model="editCargo"></el-input>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="12" :offset="0">
          <el-form>
            <el-form-item label="货物数量">
              <el-input v-model="editCount"></el-input>
            </el-form-item>
            <el-form-item label="货物体积">
              <el-input v-model="editCbm"></el-input>
            </el-form-item>
            <el-form-item label="货物重量">
              <el-input v-model="editWeight"></el-input>
            </el-form-item>
            <el-form-item label="备注">
              <el-input v-model="editMemo"></el-input>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 图片查看弹窗 -->
    <el-dialog title="图片查看" :visible.sync="imgVisible" width="60%" center>
      <img :src="warhouseImg" alt="" style="width: 100%" />
      <span slot="footer" class="dialog-footer">
        <el-button @click="imgVisible = false">取 消</el-button>
        <el-button type="primary" @click="imgVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import * as XLSX from "xlsx";
import {
  getWarHouseOrder,
  delWarHouseOrder,
  editWarHouseOrder,
  getLogistics,
} from "@/api/api";
import moment from "moment";

export default {
  data() {
    return {
      tableData: [],
      loading: false,
      page: 1,
      list: 5,
      total: 0, // 总页数
      currentPage: 1,
      editId: "",
      editContact: "",
      editPhone: "",
      editLogistics: "",
      editLogisticNo: "",
      editCargo: "",
      editCount: "",
      editCbm: "",
      editWeight: "",
      editMemo: "",
      dialogVisible: false,
      logistics: [],
      warhouseImg: "",
      imgVisible: false,
    };
  },
  methods: {
    //导出Excel表格
    exportExcel() {
      // 创建工作簿
      const wb = XLSX.utils.book_new();
      // 将数据转换为工作表
      const ws = XLSX.utils.json_to_sheet(this.tableData);
      // 添加工作表到工作簿
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      // 导出 Excel 文件
      XLSX.writeFile(wb, "导出数据.xlsx");
    },
    checkImg(img) {
      this.warhouseImg = img;
      this.imgVisible = true;
    },
    getWarHouseOrders() {
      this.loading = true;
      const token = localStorage.getItem("token");
      getWarHouseOrder(token, this.page, this.list).then((res) => {
        if (res.data.code === 200) {
          this.loading = false;
          this.tableData = res.data.data.data;
          this.total = res.data.data.total;
        } else {
          this.loading = false;
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      });
    },
    delWarHouseOrders(id) {
      const token = localStorage.getItem("token");
      delWarHouseOrder(token, id).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("删除成功");
          this.getWarHouseOrders();
        } else {
          this.message.error("删除失败" + res.msg);
        }
      });
    },
    changePage(page) {
      if (page < 1 || page > this.total) return;
      this.currentPage = page;
      this.page = page;
      this.getWarHouseOrders();
    },
    // 时间格式化
    formatDate(date) {
      return moment.unix(date).format("YYYY-MM-DD HH:mm:ss");
    },
    openEditDialog(row) {
      this.dialogVisible = true;
      this.editId = row.id;
      this.editContact = row.contact;
      this.editPhone = row.phone;
      this.editLogistics = row.logistics;
      this.editLogisticNo = row.logistic_no;
      this.editCargo = row.cargo;
      this.editCount = row.count;
      this.editCbm = row.cbm;
      this.editWeight = row.weight;
      this.editMemo = row.memo;
    },
    editWarHouseOrders() {
      const token = token;
      editWarHouseOrder(
        token,
        this.editId,
        this.editContact,
        this.editPhone,
        this.editLogistics,
        this.editLogisticNo,
        this.editCargo,
        this.editCount,
        this.editCbm,
        this.editWeight,
        this.editMemo
      ).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("修改成功!");
          this.getWarHouseOrders();
        } else {
          this.$message.error("修改失败" + res.data.msg);
        }
      });
    },
    getLogistic() {
      const token = localStorage.getItem("token");
      getLogistics(token).then((res) => {
        if (res.data.code === 200) {
          this.logistics = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
  },
  created() {
    this.getWarHouseOrders();
    this.getLogistic();
  },
};
</script>

<style></style>
