<template>
  <div>
    <div style="margin: 0 0 10px 10px">
      <a
        target="_blank"
        href="https://api.jq56.com/api/admin/exportVehOrderExcel"
      >
        <el-button type="success">导出为车证订单表格</el-button>
      </a>
    </div>
    <!-- 数据展示表格 -->
    <el-table :data="tableData" border stripe style="width: 100%">
      <el-table-column type="expand">
        <template slot-scope="props">
          <el-row>
            <el-col :span="10" :offset="0">
              <el-form
                label-position="left"
                inline
                class="demo-table-expand"
                style="margin-left: 10px"
              >
                <el-form-item label="下单用户id">
                  <span>{{ props.row.userid }}</span>
                </el-form-item>
                <el-form-item label="车证订单号">
                  <span>{{ props.row.orderno }}</span>
                </el-form-item>
                <el-form-item label="展会名称">
                  <span>{{ props.row.ename }}</span>
                </el-form-item>
                <el-form-item label="展商名称">
                  <span>{{ props.row.ebusiness }}</span>
                </el-form-item>
                <el-form-item label="展商位置">
                  <span>{{ props.row.ehall }}馆{{ props.row.eloc }}号位</span>
                </el-form-item>
                <el-form-item label="车辆类型">
                  <span>{{ props.row.vehtype }}</span>
                </el-form-item>
              </el-form>
            </el-col>
            <el-col :span="7" :offset="0">
              <el-form label-position="left" inline class="demo-table-expand">
                <el-form-item label="进馆日期">
                  <span>{{ props.row.joindate }}</span>
                </el-form-item>
                <el-form-item label="进馆时间">
                  <span>{{ props.row.jointime }}</span>
                </el-form-item>
                <el-form-item label="进馆车牌">
                  <span>{{ props.row.license }}</span>
                </el-form-item>
                <el-form-item label="车辆用途">
                  <span>{{ props.row.vehuse }}</span>
                </el-form-item>
                <el-form-item label="订单总支付">
                  <span>{{ props.row.money }}</span>
                </el-form-item>
                <el-form-item label="支付时车证费用">
                  <span>{{ props.row.license_price }}</span>
                </el-form-item>
                <el-form-item label="支付时车证押金">
                  <span>{{ props.row.license_deposit }}</span>
                </el-form-item>
              </el-form>
            </el-col>
            <el-col :span="7" :offset="0">
              <el-form label-position="left" inline class="demo-table-expand">
                <el-form-item label="联系人">
                  <span>{{ props.row.contact }}</span>
                </el-form-item>
                <el-form-item label="联系电话">
                  <span>{{ props.row.phone }}</span>
                </el-form-item>
                <el-form-item label="司机电话">
                  <span>{{ props.row.driverphone }}</span>
                </el-form-item>
                <el-form-item label="是否支付">
                  <span v-if="props.row.is_pay === '1'">已支付</span>
                  <span v-else>未支付</span>
                </el-form-item>
              </el-form>
            </el-col>
          </el-row>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="id" label="id"> </el-table-column> -->
      <el-table-column prop="orderno" label="车证订单号"> </el-table-column>
      <el-table-column prop="ename" label="展会名称"> </el-table-column>
      <el-table-column prop="ebusiness" label="展商名称"> </el-table-column>
      <el-table-column prop="licensetype" label="车证类型"> </el-table-column>
      <el-table-column prop="license" label="进馆车牌"> </el-table-column>
      <el-table-column prop="vehuse" label="车辆用途"> </el-table-column>
      <el-table-column prop="vehtype" label="车辆类型"> </el-table-column>
      <el-table-column prop="money" label="订单价格"> </el-table-column>
      <el-table-column prop="memo" label="备注"> </el-table-column>
      <el-table-column prop="is_pay" label="是否支付">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.is_pay === '1'" type="success">已支付</el-tag>
          <el-tag v-else type="danger">未支付</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="180">
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.is_pay === '0'"
            type="primary"
            @click="openEditDialog(scope.row)"
            >修改</el-button
          >
          <el-button v-else disabled type="primary">修改</el-button>
          <el-popconfirm
            v-if="scope.row.is_pay === '0'"
            title="确定删除吗？"
            @confirm="delVehOrders(scope.row.orderno)"
          >
            <el-button type="danger" slot="reference" style="margin-left: 10px"
              >删除</el-button
            >
          </el-popconfirm>
          <el-button
            v-else
            type="danger"
            disabled
            slot="reference"
            style="margin-left: 10px"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <el-row :gutter="20" style="margin: 0 20px 0 20px">
      <el-col :span="12" :offset="0" style="line-height: 60px">
        <span>共 {{ total }} 页</span>
        <span style="margin-left: 20px">显示页数</span>
        <el-input-number
          v-model="list"
          controls-position="right"
          @change="getVehOrders()"
          :min="1"
          :max="100"
          style="margin-left: 10px"
        ></el-input-number>
      </el-col>
      <el-col :span="12" :offset="0">
        <div
          style="
            display: flex;
            justify-content: right;
            align-items: right;
            margin-top: 20px;
          "
        >
          <el-button
            round
            style="padding: 10px"
            @click="changePage(currentPage - 1)"
            :disabled="currentPage === 1"
          >
            <i class="el-icon-arrow-left"></i>
          </el-button>

          <!-- 显示第一页 -->
          <div
            @click="changePage(1)"
            :class="{ 'active-page': currentPage === 1 }"
            class="page-button"
          >
            1
          </div>

          <!-- 显示省略号 -->
          <span v-if="showLeftEllipsis">...</span>

          <!-- 显示当前页附近的页码 -->
          <div
            v-for="page in filteredPages"
            :key="page"
            @click="changePage(page)"
            :class="{ 'active-page': currentPage === page }"
            class="page-button"
          >
            {{ page }}
          </div>

          <!-- 显示省略号 -->
          <span v-if="showRightEllipsis">...</span>

          <!-- 显示最后一页 -->
          <div
            @click="changePage(total)"
            :class="{ 'active-page': currentPage === total }"
            class="page-button"
          >
            {{ total }}
          </div>

          <el-button
            round
            style="padding: 10px"
            @click="changePage(currentPage + 1)"
            :disabled="currentPage === total"
          >
            <i class="el-icon-arrow-right"></i>
          </el-button>
        </div>
      </el-col>
    </el-row>
    <!-- 修改弹窗 -->
    <el-dialog title="提示" :visible.sync="dialogVisible" width="80%">
      <el-row>
        <el-col :span="8" :offset="0">
          <el-form label-position="left" inline>
            <el-form-item label="展商名称">
              <el-input v-model="editData.ebusiness"></el-input>
            </el-form-item>
            <el-form-item label="进馆车牌">
              <el-input v-model="editData.license"></el-input>
            </el-form-item>
            <el-form-item label="车证类型">
              <el-input v-model="editData.licensetype"></el-input>
            </el-form-item>
            <el-form-item label="车辆类型">
              <el-input v-model="editData.vehtype"></el-input>
            </el-form-item>
            <el-form-item label="车辆用途">
              <el-input v-model="editData.vehuse"></el-input>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="8" :offset="0">
          <el-form label-position="left" inline>
            <el-form-item label="进馆日期">
              <el-input v-model="editData.joindate"></el-input>
            </el-form-item>
            <el-form-item label="进馆时间">
              <el-input v-model="editData.jointime"></el-input>
            </el-form-item>
            <el-form-item label="订单价格">
              <el-input v-model="editData.money"></el-input>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="8" :offset="0">
          <el-form label-position="left" inline>
            <el-form-item label="联系人">
              <el-input v-model="editData.contact"></el-input>
            </el-form-item>
            <el-form-item label="联系电话">
              <el-input v-model="editData.phone"></el-input>
            </el-form-item>
            <el-form-item label="司机电话">
              <el-input v-model="editData.driverphone"></el-input>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editVehOrders()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  delVehOrder,
  editVehOrder,
  exportVehOrderExcel,
  getVehOrder,
} from "@/api/api";
export default {
  data() {
    return {
      tableData: [],
      page: 1,
      list: 20,
      total: 0, // 总页数
      pageRange: 3, // 当前页前后显示的页数范围
      currentPage: 1,
      dialogVisible: false,
      editData: [],
    };
  },
  methods: {
    exportVehOrderExcels() {
      exportVehOrderExcel()
        .then((response) => {
          // 创建下载链接
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;

          // 设置下载的文件名
          link.setAttribute("download", "车证订单.xlsx");

          // 触发下载
          document.body.appendChild(link);
          link.click();

          // 清除链接
          window.URL.revokeObjectURL(url);
          document.body.removeChild(link);
        })
        .catch((error) => {
          console.error("导出失败:", error);
        });
    },
    delVehOrders(orderno) {
      const token = localStorage.getItem("token");
      const loading = this.$loading({
        lock: true,
        text: "正在删除",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      delVehOrder(token, orderno).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("删除成功！");
          this.getVehOrders();
          loading.close();
        } else {
          this.$message.error(res.data.msg);
          loading.close();
        }
      });
    },
    editVehOrders() {
      const loading = this.$loading({
        lock: true,
        text: "正在上传",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const token = localStorage.getItem("token");
      editVehOrder(
        token,
        this.editData.orderno,
        this.editData.ebusiness,
        this.editData.license,
        this.editData.licensetype,
        this.editData.vehtype,
        this.editData.vehuse,
        this.editData.joindate,
        this.editData.jointime,
        this.editData.money,
        this.editData.contact,
        this.editData.phone,
        this.editData.driverphone
      ).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("修改成功！");
          this.getVehOrders();
          this.dialogVisible = false;
          loading.close();
        } else {
          this.$message.error(res.data.msg);
          loading.close();
        }
      });
    },
    openEditDialog(row) {
      this.editData = row;
      this.dialogVisible = true;
    },
    changePage(page) {
      if (page < 1 || page > this.total) return;
      this.currentPage = page;
      this.page = page;
      this.getVehOrders();
    },
    getVehOrders() {
      const token = localStorage.getItem("token");
      const loading = this.$loading({
        lock: true,
        text: "正在加载",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      getVehOrder(token, this.page, this.list)
        .then((res) => {
          if (res.data.code === 200) {
            this.tableData = res.data.data.data;
            this.total = res.data.data.total;
            loading.close();
          } else {
            this.$message.error(res.data.msg);
            loading.close();
          }
        })
        .catch((res) => {
          this.$message.error(res.data.msg);
          loading.close();
        });
    },
  },
  created() {
    this.getVehOrders();
  },
  computed: {
    // 动态计算需要显示的页码并过滤
    filteredPages() {
      const pages = [];
      const startPage = Math.max(2, this.currentPage - this.pageRange);
      const endPage = Math.min(
        this.total - 1,
        this.currentPage + this.pageRange
      );

      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }

      return pages;
    },
    // 是否显示左边的省略号
    showLeftEllipsis() {
      return this.currentPage - this.pageRange > 2;
    },
    // 是否显示右边的省略号
    showRightEllipsis() {
      return this.currentPage + this.pageRange < this.total - 1;
    },
  },
};
</script>

<style>
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
</style>
