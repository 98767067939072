<template>
  <div>
    <el-table :data="tableData" v-loading="loading" stripe style="width: 100%">
      <el-table-column prop="id" label="ID"> </el-table-column>
      <el-table-column prop="orderno" label="关联现场服务订单">
      </el-table-column>
      <el-table-column prop="vehorderno" label="关联车证订单">
      </el-table-column>
      <el-table-column prop="plate" label="出入场车牌"> </el-table-column>
      <el-table-column prop="ename" label="展会名称"> </el-table-column>
      <el-table-column prop="is_in" label="是否入场">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.is_in === 0" type="danger">未入场</el-tag>
          <el-tag v-if="scope.row.is_in === 1" type="success">已入场</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="is_out" label="是否出场">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.is_out === 0" type="danger">未出场</el-tag>
          <el-tag v-if="scope.row.is_out === 1" type="success">已出场</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="intime" label="入场时间">
        <template slot-scope="scope">
          <span>{{ formatDate(scope.row.intime) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="outtime" label="出场时间">
        <template slot-scope="scope">
          <span>{{ formatDate(scope.row.outtime) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="场内滞留时间">
        <template slot-scope="scope">
          <span>{{
            formatStayTime(scope.row.outtime - scope.row.intime)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="money" label="场内产生费用"> </el-table-column>
      <el-table-column prop="in_employee_name" label="入场操作员工姓名">
      </el-table-column>
      <el-table-column prop="in_employee_phone" label="入场操作员工电话">
      </el-table-column>
      <el-table-column prop="out_employee_name" label="出场操作员工姓名">
      </el-table-column>
      <el-table-column prop="out_employee_phone" label="出场操作员工电话">
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <el-row :gutter="20" style="margin: 0 20px 0 20px">
      <el-col :span="12" :offset="0" style="line-height: 60px">
        <span>共 {{ total }} 页</span>
        <span style="margin-left: 20px">显示页数</span>
        <el-input-number
          v-model="list"
          controls-position="right"
          @change="getExhibitOrders()"
          :min="1"
          :max="100"
          style="margin-left: 10px"
        ></el-input-number>
      </el-col>
      <el-col :span="12" :offset="0">
        <div
          style="
            display: flex;
            justify-content: right;
            align-items: right;
            margin-top: 20px;
          "
        >
          <el-button
            round
            style="padding: 10px"
            @click="changePage(currentPage - 1)"
            :disabled="currentPage === 1"
          >
            <i class="el-icon-arrow-left"></i>
          </el-button>

          <!-- 显示第一页 -->
          <div
            @click="changePage(1)"
            :class="{ 'active-page': currentPage === 1 }"
            class="page-button"
          >
            1
          </div>

          <!-- 显示省略号 -->
          <span v-if="showLeftEllipsis">...</span>

          <!-- 显示当前页附近的页码 -->
          <div
            v-for="page in filteredPages"
            :key="page"
            @click="changePage(page)"
            :class="{ 'active-page': currentPage === page }"
            class="page-button"
          >
            {{ page }}
          </div>

          <!-- 显示省略号 -->
          <span v-if="showRightEllipsis">...</span>

          <!-- 显示最后一页 -->
          <div
            @click="changePage(total)"
            :class="{ 'active-page': currentPage === total }"
            class="page-button"
          >
            {{ total }}
          </div>

          <el-button
            round
            style="padding: 10px"
            @click="changePage(currentPage + 1)"
            :disabled="currentPage === total"
          >
            <i class="el-icon-arrow-right"></i>
          </el-button>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import moment from "moment";
import { getVehEntry } from "@/api/api";
export default {
  data() {
    return {
      tableData: [],
      page: 1,
      list: 20,
      total: 0, // 总页数
      pageRange: 3, // 当前页前后显示的页数范围
      currentPage: 1,
      loading: false,
    };
  },
  methods: {
    getVehEntrys() {
      this.loading = true;
      const token = localStorage.getItem("token");
      getVehEntry(token, this.page, this.list)
        .then((res) => {
          if (res.data.code === 200) {
            this.tableData = res.data.data.data;
            this.total = res.data.data.total;
            this.loading = false;
          } else {
            this.$message.error(res.data.msg);
            this.loading = false;
          }
        })
        .catch((res) => {
          this.$message.error(res.data.msg);
          this.loading = false;
        });
    },
    // 时间格式化
    formatDate(date) {
      return moment.unix(date).format("YYYY-MM-DD HH:mm:ss");
    },
    formatStayTime(stayTimeInSeconds) {
      // 将时间从秒转换为小时
      const stayTimeInHours = stayTimeInSeconds / 3600;

      // 检查滞留时间是否小于 1 小时
      if (stayTimeInHours < 1) {
        return "少于一小时";
      }

      // 保留一位小数
      return stayTimeInHours.toFixed(1) + " 小时";
    },
  },
  created() {
    this.getVehEntrys();
  },
  computed: {
    // 动态计算需要显示的页码并过滤
    filteredPages() {
      const pages = [];
      const startPage = Math.max(2, this.currentPage - this.pageRange);
      const endPage = Math.min(
        this.total - 1,
        this.currentPage + this.pageRange
      );

      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }

      return pages;
    },
    // 是否显示左边的省略号
    showLeftEllipsis() {
      return this.currentPage - this.pageRange > 2;
    },
    // 是否显示右边的省略号
    showRightEllipsis() {
      return this.currentPage + this.pageRange < this.total - 1;
    },
  },
};
</script>

<style></style>
