import axios from "axios";

const axiosInstance = axios.create({
  baseURL: "https://api.jq56.com/",
});

// 登录接口
export const Login = (username, password) => {
  return axiosInstance.post("/api/admin/login", {
    username: username,
    password: password,
  });
};

// 退出登录
export const LogOut = (token) => {
  return axiosInstance.post("/api/admin/logout", {
    token: token,
  });
};

// 修改密码
export const changePassword = (token, oldPassword, newPassword) => {
  return axiosInstance.post("/api/admin/changePwd", {
    token: token,
    oldPassword: oldPassword,
    newPassword: newPassword,
  });
};

// 获取后台会员列表
export const GetMembersList = (token, page, list) => {
  return axiosInstance.post("/api/admin/membersList", {
    token: token,
    page: page,
    list: list,
  });
};

// 获取单个会员列表
export const GetMember = (token, id) => {
  return axiosInstance.post("/api/admin/getMember", {
    token: token,
    id: id,
  });
};

// 删除会员
export const DeleteMember = (token, id) => {
  return axiosInstance.post("/api/admin/deleteMember", {
    token: token,
    id: id,
  });
};

// 添加会员
export const AddMember = (token, username, password) => {
  return axiosInstance.post("/api/admin/addMember", {
    token: token,
    username: username,
    password: password,
  });
};

// 编辑会员
export const EditMember = (token, id, data) => {
  return axiosInstance.post("/api/admin/editMember", {
    token: token,
    id: id,
    ...data, // 使用展开运算符直接传递对象的属性
  });
};

// 获取SMTP服务
export const GetSMTP = (token) => {
  return axiosInstance.post("/api/admin/getSmtpConfig", {
    token: token,
  });
};

// 编辑SMTP服务
export const EditSMTP = (
  token,
  smtpHost,
  smtpPort,
  smtpUser,
  smtpPass,
  smtpSender,
  useSSL,
  smtpAuth
) => {
  return axiosInstance.post("/api/admin/editSmtpConfig", {
    token: token,
    smtpHost: smtpHost,
    smtpPort: smtpPort,
    smtpUser: smtpUser,
    smtpPass: smtpPass,
    smtpSender: smtpSender,
    useSSL: useSSL,
    smtpAuth: smtpAuth,
  });
};

// 发送邮件接口
export const SendMail = (token, sendTo, subject, body) => {
  return axiosInstance.post("/api/admin/sendMail", {
    token: token,
    sendTo: sendTo,
    subject: subject,
    body: body,
  });
};

// 发送邮件接口(备选方案)
export const altSendMail = (token, sendTo, subject, altBody) => {
  return axiosInstance.post("/api/admin/sendMail", {
    token: token,
    sendTo: sendTo,
    subject: subject,
    altBody: altBody,
  });
};

// 获取会员等级规则
export const GetUserLevel = (token) => {
  return axiosInstance.post("/api/admin/getUserLevelList", {
    token: token,
  });
};

// 添加会员等级规则
export const AddUserLevel = (token, level, name, exp, discount) => {
  return axiosInstance.post("/api/admin/addUserLevel", {
    token: token,
    level: level, //等级
    name: name, //等级名称
    exp: exp, //经验值
    discount: discount, //会员折扣
  });
};

// 编辑会员等级规则
export const EditUserLevel = (token, id, level, name, exp, discount) => {
  return axiosInstance.post("/api/admin/editUserLevel", {
    token: token,
    id: id,
    level: level, //等级
    name: name, //等级名称
    exp: exp, //经验值
    discount: discount, //会员折扣
  });
};

// 删除会员等级规则
export const DeleteUserLevel = (token, id) => {
  return axiosInstance.post("/api/admin/deleteUserLevel", {
    token: token,
    id: id,
  });
};

// 获取新闻列表
export const GetNewsList = (page, list) => {
  return axiosInstance.post("/api/admin/getNewsList", {
    page: page,
    list: list,
  });
};

// 删除新闻列表
export const DeleteNews = (token, id) => {
  return axiosInstance.post("/api/admin/deleteNews", {
    token: token,
    id: id,
  });
};

// 新增新闻
export const AddNews = (token, type, title, content, url) => {
  return axiosInstance.post("/api/admin/addNews", {
    token: token,
    type: type,
    title: title,
    content: content,
    url: url,
  });
};

// 修改新闻
export const updateNews = (token, id, type, title, content, url) => {
  return axiosInstance.post("/api/admin/editNews", {
    token: token,
    id: id,
    type: type,
    title: title,
    content: content,
    url: url,
  });
};

// 获取充值记录
export const GetRechargeList = (token, page, list) => {
  return axiosInstance.post("/api/admin/payRecord", {
    token: token,
    page: page,
    list: list,
  });
};

// 获取会员消费记录
export const getExpenditure = (token, page, list) => {
  return axiosInstance.post("/api/admin/getExpenditure", {
    token: token,
    page: page,
    list: list,
  });
};

// 获取小程序Banner
export const GetBannerList = (token) => {
  return axiosInstance.post("/api/admin/getBannerList", {
    token: token,
  });
};

// 添加小程序Banner
export const AddBanner = (token, bannerUrl, priority, jumpTo, isShow) => {
  return axiosInstance.post("/api/admin/addBanner", {
    token: token,
    bannerUrl: bannerUrl,
    priority: priority,
    jumpTo: jumpTo,
    isShow: isShow,
  });
};

// 编辑小程序banner
export const EditBanner = (token, id, bannerUrl, priority, jumpTo, isShow) => {
  return axiosInstance.post("/api/admin/editBanner", {
    token: token,
    id: id,
    bannerUrl: bannerUrl,
    priority: priority,
    jumpTo: jumpTo,
    isShow: isShow,
  });
};

// 删除小程序banner
export const DeleteBanner = (token, id) => {
  return axiosInstance.post("/api/admin/deleteBanner", {
    token: token,
    id: id,
  });
};

// 获取全局配置列表
export const GetConfigList = (token, page, list) => {
  return axiosInstance.post("/api/admin/getConfigList", {
    token: token,
    page: page,
    list: list,
  });
};

// 编辑全局配置
export const EditConfig = (token, id, name, value, title, tip) => {
  return axiosInstance.post("/api/admin/editConfig", {
    token: token,
    id: id,
    name: name,
    value: value,
    title: title,
    tip: tip,
  });
};

// 新增全局配置
export const AddConfig = (token, name, value, title, tip) => {
  return axiosInstance.post("/api/admin/addConfig", {
    token: token,
    name: name,
    value: value,
    title: title,
    tip: tip,
  });
};

// 删除全局配置
export const DelConfig = (token, id) => {
  return axiosInstance.post("/api/admin/deleteConfig", {
    token: token,
    id: id,
  });
};

// 获取价格计算配置列表
export const CalcConfigList = (token) => {
  return axiosInstance.post("/api/admin/calcConfigList", {
    token: token,
  });
};

// 编辑价格计算配置
export const EditCalcConfig = (token, id, name, value, tip) => {
  return axiosInstance.post("/api/admin/editCalcConfig", {
    token: token,
    id: id,
    name: name,
    value: value,
    tip: tip,
  });
};

// 添加价格计算配置
export const AddCalcConfig = (token, name, value, tip) => {
  return axiosInstance.post("/api/admin/addCalcConfig", {
    token: token,
    name: name,
    value: value,
    tip: tip,
  });
};

// 删除价格计算配置
export const DelCalcConfig = (token, id) => {
  return axiosInstance.post("/api/admin/deleteCalcConfig", {
    token: token,
    id: id,
  });
};

// 获取个人实名认证列表
export const GetRealNameList = (token, page, list) => {
  return axiosInstance.post("/api/admin/getPersonRealNameList", {
    token: token,
    page: page,
    list: list,
  });
};

// 审核个人实名认证
export const reviewPersonRealName = (token, id, status, failReason) => {
  return axiosInstance.post("/api/admin/reviewPersonRealName", {
    token: token,
    //个人实名认证ID
    id: id,
    //审核状态·:待审核·1:审核通过·2:审核拒绝
    status: status,
    failReason: failReason,
  });
};

// 获取实名认证AI审核开关状态
export const getRealNameAiReviewSwitch = (token) => {
  return axiosInstance.post("/api/admin/getRealNameAiReviewSwitch", {
    token: token,
  });
};

// 开启关闭实名认证AI审核
export const switchRealNameAiReview = (token, status) => {
  return axiosInstance.post("/api/admin/switchRealNameAiReview", {
    token: token,
    status: status,
  });
};

//获取小推车订单列表
export const getCartList = (token, page, list) => {
  return axiosInstance.post("/api/admin/getCartList", {
    token: token,
    page: page,
    list: list,
  });
};

//获取员工列表
export const getEmployeeList = (token, page, list) => {
  return axiosInstance.post("/api/admin/getEmployeeList", {
    token: token,
    page: page,
    list: list,
  });
};

// 添加员工
export const addEmployee = (
  token,
  username,
  password,
  name,
  phone,
  ename,
  ehall
) => {
  return axiosInstance.post("/api/admin/addEmployee", {
    token: token,
    username: username,
    password: password,
    name: name,
    phone: phone,
    ename: ename,
    ehall: ehall,
  });
};

// 编辑员工
export const editEmployee = (
  token,
  id,
  username,
  password,
  name,
  avatar,
  phone
) => {
  return axiosInstance.post("/api/admin/editEmployee", {
    token: token,
    id: id,
    username: username,
    password: password,
    name: name,
    avatar: avatar,
    phone: phone,
  });
};

// 获取单个员工信息
export const getEmployeeInfo = (token, id) => {
  return axiosInstance.post("/api/admin/getEmployeeInfo", {
    token: token,
    id: id,
  });
};

// 删除员工
export const deleteEmployee = (token, id) => {
  return axiosInstance.post("/api/admin/deleteEmployee", {
    token: token,
    id: id,
  });
};

// 删除小推车
export const deleteCartOrder = (token, id) => {
  return axiosInstance.post("/api/admin/deleteCartOrder", {
    token: token,
    id: id,
  });
};

// 编辑小推车订单
export const editCartOrder = (
  token,
  id,
  userId,
  price,
  amount,
  totalPrice,
  fromAddress,
  toAddress,
  memo,
  createtime,
  updatetime,
  reviewerId,
  status
) => {
  return axiosInstance.post("/api/admin/editCartOrder", {
    token: token,
    id: id,
    userId: userId,
    price: price,
    amount: amount,
    totalPrice: totalPrice,
    fromAddress: fromAddress,
    toAddress: toAddress,
    memo: memo,
    createtime: createtime,
    updatetime: updatetime,
    reviewerId: reviewerId,
    status: status,
  });
};

// 获取会员发票列表
export const getMemberInvoicesList = (token, page, list) => {
  return axiosInstance.post("/api/admin/getMemberInvoicesList", {
    token: token,
    page: page,
    list: list,
  });
};

// 获取会员发票抬头列表
export const getInvoiceHeadList = (token, page, list) => {
  return axiosInstance.post("/api/admin/getInvoiceHeadList", {
    token: token,
    page: page,
    list: list,
  });
};

// 获取退款订单列表
export const getRefundOrderList = (token, page, list) => {
  return axiosInstance.post("/api/admin/refundOrderList", {
    token: token,
    page: page,
    list: list,
  });
};

// 获取单个退款订单
export const getRefundOrder = (token, id) => {
  return axiosInstance.post("/api/admin/getRefundOrder", {
    token: token,
    id: id,
  });
};

// 审核订单
export const reviewRefundOrder = (token, id, status, refuseReason) => {
  return axiosInstance.post("/api/admin/reviewRefundOrder", {
    token: token,
    id: id,
    status: status,
    refuseReason: refuseReason,
  });
};

// 获取现场服务展商
export const getEnameList = () => {
  return axiosInstance.post("/api/admin/getEnameList");
};

// 添加现场服务展商
export const addEname = (
  label,
  value,
  money,
  lengthValue,
  widthValue,
  heightValue,
  weightValue,
  excursionLimits,
  manageMoney,
  warhouseMoney,
  emptyBoxMoney,
  vehLicenseMoney,
  vehLicenseDeposit,
  timeLimit,
  timeOutPrice
) => {
  return axiosInstance.post("/api/admin/addEname", {
    label: label,
    value: value,
    money: money,
    lengthValue: lengthValue,
    widthValue: widthValue,
    heightValue: heightValue,
    weightValue: weightValue,
    excursionLimits: excursionLimits,
    manageMoney: manageMoney,
    warhouseMoney: warhouseMoney,
    emptyBoxMoney: emptyBoxMoney,
    vehLicenseMoney: vehLicenseMoney,
    vehLicenseDeposit: vehLicenseDeposit,
    timeLimit: timeLimit,
    timeOutPrice: timeOutPrice,
  });
};

// 修改现场服务展商
export const editEname = (
  id,
  label,
  value,
  money,
  lengthValue,
  widthValue,
  heightValue,
  weightValue,
  excursionLimits,
  manageMoney,
  warhouseMoney,
  isShow,
  emptyBoxMoney,
  vehLicenseMoney,
  vehLicenseDeposit,
  timeLimit,
  timeOutPrice
) => {
  return axiosInstance.post("/api/admin/updateEname", {
    id: id,
    label: label,
    value: value,
    money: money,
    lengthValue: lengthValue,
    widthValue: widthValue,
    heightValue: heightValue,
    weightValue: weightValue,
    excursionLimits: excursionLimits,
    manageMoney: manageMoney,
    warhouseMoney: warhouseMoney,
    isShow: isShow,
    emptyBoxMoney: emptyBoxMoney,
    vehLicenseMoney: vehLicenseMoney,
    vehLicenseDeposit: vehLicenseDeposit,
    timeLimit: timeLimit,
    timeOutPrice: timeOutPrice,
  });
};

//删除现场服务展商
export const delEname = (id) => {
  return axiosInstance.post("/api/admin/delEname", {
    id: id,
  });
};

//获取现场服务展馆
export const getVenue = (token, page, list, key) => {
  return axiosInstance.post("/api/admin/getVenue", {
    token: token,
    page: page,
    list: list,
    key: key,
  });
};

//删除现场服务展馆
export const delVenue = (id) => {
  return axiosInstance.post("/api/admin/delVenue", {
    id: id,
  });
};

//添加现场服务展馆
export const addVenue = (id, label, value) => {
  return axiosInstance.post("/api/admin/addVenue", {
    id: id,
    label: label,
    value: value,
  });
};

//批量添加现场服务展馆
export const addVenueList = (id, label, value) => {
  return axiosInstance.post("/api/admin/addVenueList", {
    id: id,
    label: label,
    value: value,
  });
};

//修改现场服务展馆
export const updateVenue = (id, label, value) => {
  return axiosInstance.post("/api/admin/updateVenue", {
    id: id,
    label: label,
    value: value,
  });
};

// 获取机力租赁分类
export const getRentType = (token) => {
  return axiosInstance.post("/api/admin/getRentType", {
    token: token,
  });
};

// 编辑机力租赁分类
export const editRentType = (token, id, type) => {
  return axiosInstance.post("/api/admin/editRentType", {
    token: token,
    id: id,
    type: type,
  });
};

// 删除机力租赁分类
export const delRentType = (token, id, eid) => {
  return axiosInstance.post("/api/admin/delRentType", {
    token: token,
    id: id,
    eid: eid,
  });
};

// 新增机力租赁分类
export const addRentType = (token, type) => {
  return axiosInstance.post("/api/admin/addRentType", {
    token: token,
    type: type,
  });
};

// 获取机力租赁eid下的所有租赁商品
export const getRentProducts = (token, eid) => {
  return axiosInstance.post("/api/admin/getRentProducts", {
    token: token,
    eid: eid,
  });
};

// 删除对应pid租赁商品
export const delRentProduct = (token, pid) => {
  return axiosInstance.post("/api/admin/delRentProduct", {
    token: token,
    pid: pid,
  });
};

// 添加租赁商品
export const addRentProduct = (
  token,
  eid,
  image,
  product,
  load,
  price,
  memo
) => {
  return axiosInstance.post("/api/admin/addRentProduct", {
    token: token,
    eid: eid,
    image: image,
    product: product,
    load: load,
    price: price,
    memo: memo,
  });
};

// 编辑租赁商品
export const updateRentProduct = (
  token,
  id,
  image,
  product,
  load,
  price,
  memo
) => {
  return axiosInstance.post("/api/admin/updateRentProduct", {
    token: token,
    id: id,
    image: image,
    product: product,
    load: load,
    price: price,
    memo: memo,
  });
};

// 获取现场服务单
export const getExhibitOrder = (token, page, list, ename, type) => {
  return axiosInstance.post("/api/admin/getExhibitOrder", {
    token: token,
    page: page,
    list: list,
    ename: ename,
    type: type,
  });
};

// 获取现场服务单货物数据
export const getExhibitOrderGoods = (token, id) => {
  return axiosInstance.post("/api/admin/getExhibitOrderGoods", {
    token: token,
    id: id,
  });
};

// 根据orderno获取现场服务单货物数据
export const getExhibitOrderGoodss = (token, orderno) => {
  return axiosInstance.post("/api/admin/getExhibitOrderGoodss", {
    token: token,
    orderno: orderno,
  });
};

// 搜索现场服务单
export const searchExhibitOrder = (token, orderno, ebusiness) => {
  return axiosInstance.post("/api/admin/searchExhibitOrder", {
    token: token,
    orderno: orderno,
    ebusiness: ebusiness,
  });
};

// 删除现场服务单货物数据
export const deleteGoodsItem = (token, orderno, goodsid) => {
  return axiosInstance.post("/api/admin/deleteGoodsItem", {
    token: token,
    orderno: orderno,
    goodsid: goodsid,
  });
};

// 更新现场服务单货物数据
export const updateGoodsItem = (
  token,
  orderno,
  goodsid,
  width,
  length,
  height,
  weight,
  count,
  cbm,
  totalcbm,
  totalweight,
  packages,
  memo
) => {
  return axiosInstance.post("/api/admin/updateGoodsItem", {
    token: token,
    orderno: orderno,
    goodsid: goodsid,
    width: width,
    length: length,
    height: height,
    weight: weight,
    count: count,
    cbm: cbm,
    totalcbm: totalcbm,
    totalweight: totalweight,
    packages: packages,
    memo: memo,
  });
};

// 创建现场服务订单
export const createServiceOrder = (
  token,
  // userid,
  exhibitionName,
  businessExhibitionName,
  contacts,
  phone,
  exhibitionHall,
  exhibitionLocation,
  type,
  license,
  memo
) => {
  return axiosInstance.post("/api/admin/createServiceOrder", {
    token: token,
    // userid: userid,
    exhibitionName: exhibitionName,
    businessExhibitionName: businessExhibitionName,
    contacts: contacts,
    phone: phone,
    exhibitionHall: exhibitionHall,
    exhibitionLocation: exhibitionLocation,
    type: type,
    license: license,
    memo: memo,
  });
};

// 根据EID查询对应展馆
export const getEhalls = (token, eid) => {
  return axiosInstance.post("/api/admin/getEhalls", {
    token: token,
    eid: eid,
  });
};

// 根据手机号查询用户信息
export const getUsers = (token, phone) => {
  return axiosInstance.post("/api/admin/getUsers", {
    token: token,
    phone: phone,
  });
};

// 添加现场服务订单数据
export const completeServiceOrder = (
  token,
  orderno,
  width,
  length,
  height,
  weight,
  count,
  cbm,
  totalcbm,
  totalweight,
  packages,
  memo
) => {
  return axiosInstance.post("/api/admin/completeServiceOrder", {
    token: token,
    orderno: orderno,
    width: width,
    length: length,
    height: height,
    weight: weight,
    count: count,
    cbm: cbm,
    totalcbm: totalcbm,
    totalweight: totalweight,
    packages: packages,
    memo: memo,
  });
};

// 获取车辆类型数据
export const getVehTypes = (token, page, list) => {
  return axiosInstance.post("/api/admin/getVehTypes", {
    token: token,
    page: page,
    list: list,
  });
};

// 增加车辆类型数据
export const addVehTypes = (token, type) => {
  return axiosInstance.post("/api/admin/addVehTypes", {
    token: token,
    type: type,
  });
};

// 修改车辆类型数据
export const editVehTypes = (token, id, type) => {
  return axiosInstance.post("/api/admin/editVehTypes", {
    token: token,
    id: id,
    type: type,
  });
};

// 删除车辆类型数据
export const delVehTypes = (token, id) => {
  return axiosInstance.post("/api/admin/delVehTypes", {
    token: token,
    id: id,
  });
};

// 查询展品质量预报 货物具体信息
export const getExhibitInfo = (token) => {
  return axiosInstance.post("/api/admin/getExhibitInfo", {
    token: token,
  });
};

// 添加展品质量预报
export const addExhibitInfo = (
  token,
  exhibitName,
  length,
  width,
  height,
  cbm,
  weight,
  memo
) => {
  return axiosInstance.post("/api/admin/addExhibitInfo", {
    token: token,
    exhibitName: exhibitName,
    length: length,
    width: width,
    height: height,
    cbm: cbm,
    weight: weight,
    memo: memo,
  });
};

// 删除展品质量预报
export const delExhibitInfo = (token, id) => {
  return axiosInstance.post("/api/admin/delExhibitInfo", {
    token: token,
    id: id,
  });
};

// 修改展品质量预报
export const editExhibitInfo = (
  token,
  id,
  exhibitName,
  length,
  width,
  height,
  cbm,
  weight,
  memo
) => {
  return axiosInstance.post("/api/admin/editExhibitInfo", {
    token: token,
    id: id,
    exhibitName: exhibitName,
    length: length,
    width: width,
    height: height,
    cbm: cbm,
    weight: weight,
    memo: memo,
  });
};

// 查询展品质量预报 特殊服务信息
export const getExhibitMacInfo = (token) => {
  return axiosInstance.post("/api/admin/getExhibitMacInfo", {
    token: token,
  });
};

// 删除展品质量预报 特殊服务信息
export const delExhibitMacInfo = (token, id) => {
  return axiosInstance.post("/api/admin/delExhibitMacInfo", {
    token: token,
    id: id,
  });
};

// 添加展品质量预报 特殊服务信息
export const addExhibitMacInfo = (
  token,
  macSize,
  count,
  useDate,
  useTime,
  assemblyTime
) => {
  return axiosInstance.post("/api/admin/addExhibitMacInfo", {
    token: token,
    macSize: macSize,
    count: count,
    useDate: useDate,
    useTime: useTime,
    assemblyTime: assemblyTime,
  });
};

// 添加展品质量预报 特殊服务信息
export const editExhibitMacInfo = (
  token,
  id,
  macSize,
  count,
  useDate,
  useTime,
  assemblyTime
) => {
  return axiosInstance.post("/api/admin/editExhibitMacInfo", {
    token: token,
    id: id,
    macSize: macSize,
    count: count,
    useDate: useDate,
    useTime: useTime,
    assemblyTime: assemblyTime,
  });
};

// 向用户推送订单信息
export const sendSubscribeMessage = (id, orderno) => {
  return axiosInstance.post("/api/admin/sendSubscribeMessage", {
    id: id,
    orderno: orderno,
  });
};

// 根据用户手机号搜索用户id
export const getUserId = (token, phone) => {
  return axiosInstance.post("/api/admin/getUserId", {
    token: token,
    phone: phone,
  });
};
// 根据用户手机号搜索用户id
export const getOrderInfo = (token, orderno) => {
  return axiosInstance.post("/api/admin/getOrderInfo", {
    token: token,
    orderno: orderno,
  });
};

// 获取仓库代收订单
export const getWarHouseOrder = (token, page, list) => {
  return axiosInstance.post("/api/admin/getWarHouseOrder", {
    token: token,
    page: page,
    list: list,
  });
};

// 删除仓库代收订单
export const delWarHouseOrder = (token, id) => {
  return axiosInstance.post("/api/admin/delWarHouseOrder", {
    token: token,
    id: id,
  });
};

// 编辑仓库代收订单
export const editWarHouseOrder = (
  token,
  id,
  contact,
  phone,
  logistics,
  logistic_no,
  cargo,
  count,
  cbm,
  weight,
  memo
) => {
  return axiosInstance.post("/api/admin/editWarHouseOrder", {
    token: token,
    id: id,
    contact: contact,
    phone: phone,
    logistics: logistics,
    logistic_no: logistic_no,
    cargo: cargo,
    count: count,
    cbm: cbm,
    weight: weight,
    memo: memo,
  });
};

// 获取承运公司
export const getLogistics = (token) => {
  return axiosInstance.post("/api/admin/getLogistics", {
    token: token,
  });
};

// 通过姓名搜索员工
export const searchEmployee = (token, name) => {
  return axiosInstance.post("/api/admin/searchEmployee", {
    token: token,
    name: name,
  });
};

// 指派订单
export const sendOrderEmployee = (token, orderno, phone) => {
  return axiosInstance.post("/api/admin/sendOrderEmployee", {
    token: token,
    orderno: orderno,
    phone: phone,
  });
};

// 设置订单已经付款
export const orderIsPay = (token, orderno) => {
  return axiosInstance.post("/api/admin/orderIsPay", {
    token: token,
    orderno: orderno,
  });
};

// 更新订单价格
export const setPrice = (token, orderno, price) => {
  return axiosInstance.post("/api/admin/setPrice", {
    token: token,
    orderno: orderno,
    price: price,
  });
};

// 根据展会名称搜索订单
export const searchOrderForEname = (token, ename) => {
  return axiosInstance.post("/api/admin/searchOrderForEname", {
    token: token,
    ename: ename,
  });
};

// 查询后台管理员列表
export const getAdminList = (token) => {
  return axiosInstance.post("/api/admin/getAdminList", {
    token: token,
  });
};

// 新增后台管理员
export const register = (username, password, nickname) => {
  return axiosInstance.post("/api/admin/register", {
    username: username,
    password: password,
    nickname: nickname,
  });
};

// 删除管理员
export const delAdmin = (token, username) => {
  return axiosInstance.post("/api/admin/delAdmin", {
    token: token,
    username: username,
  });
};

// 设置管理员状态
export const setStatusForAdmin = (token, username, status) => {
  return axiosInstance.post("/api/admin/setStatusForAdmin", {
    token: token,
    username: username,
    status: status, // 0: 禁用 1: 启用
  });
};

// 获取所有租赁订单
export const getRentOrders = (token, page, list) => {
  return axiosInstance.post("/api/admin/getRentOrders", {
    token: token,
    page: page,
    list: list,
  });
};

// 修改租赁订单
export const editRentOrders = (
  token,
  orderid,
  product,
  load,
  ename,
  usetime,
  ehall,
  eloc,
  count,
  money
) => {
  return axiosInstance.post("/api/admin/editRentOrders", {
    token: token,
    orderid: orderid,
    product: product,
    load: load,
    ename: ename,
    usetime: usetime,
    ehall: ehall,
    eloc: eloc,
    count: count,
    money: money,
  });
};

// 删除租赁订单
export const delRentOrder = (token, orderid) => {
  return axiosInstance.post("/api/admin/delRentOrder", {
    token: token,
    orderid: orderid,
  });
};

// 根据展馆名称获取现场服务展馆
export const getEhallForName = (ename) => {
  return axiosInstance.post("/api/admin/getEhallForName", {
    ename: ename,
  });
};

// 删除允许查看的展馆
export const delAllowCheck = (token, id) => {
  return axiosInstance.post("/api/admin/delAllowCheck", {
    token: token,
    id: id,
  });
};

// 添加允许查看的展馆
export const addAllowCheck = (token, epid, ename, ehall) => {
  return axiosInstance.post("/api/admin/addAllowCheck", {
    token: token,
    epid: epid,
    ename: ename,
    ehall: ehall,
  });
};

// 允许员工查看所有展会展馆
export const addAllowCheckAll = (token, epid) => {
  return axiosInstance.post("/api/admin/addAllowCheckAll", {
    token: token,
    epid: epid,
  });
};

// 手动指派订单给用户
export const sendOrderToUser = (token, orderno, userid) => {
  return axiosInstance.post("/api/admin/sendOrderToUser", {
    token: token,
    orderno: orderno,
    userid: userid,
  });
};

// 根据vid(展馆id)获取所有负责员工消息
export const getEmployeeForVid = (token, vid) => {
  return axiosInstance.post("/api/admin/getEmployeeForVid", {
    token: token,
    vid: vid,
  });
};

// 添加负责员工
export const addEmployeeForVid = (token, vid, phone) => {
  return axiosInstance.post("/api/admin/addEmployeeForVid", {
    token: token,
    vid: vid,
    phone: phone,
  });
};

// 删除负责员工
export const dropEmployeeForVid = (token, id) => {
  return axiosInstance.post("/api/admin/dropEmployeeForVid", {
    token: token,
    id: id,
  });
};

// 现场服务订单数据导出
export const exportCSV = (ename) => {
  return axiosInstance.post("/api/admin/exportCSV", {
    ename: ename,
  });
};

// 获取单个展会订单体积列表
export const getEnameTotalCbm = (ename) => {
  return axiosInstance.post("/api/admin/getEnameTotalCbm", {
    ename: ename,
  });
};

// 根据展会id获取展馆
export const getEhallForEid = (token, eid) => {
  return axiosInstance.post("/api/admin/getEhallForEid", {
    token: token,
    eid: eid,
  });
};

// 获取车证管理订单
export const getVehOrder = (token, page, list) => {
  return axiosInstance.post("/api/admin/getVehOrder", {
    token: token,
    page: page,
    list: list,
  });
};

// 编辑车证订单
export const editVehOrder = (
  token,
  orderno,
  ebusiness,
  license,
  licenseType,
  vehType,
  vehUse,
  joinDate,
  joinTime,
  money,
  contact,
  phone,
  driverphone
) => {
  return axiosInstance.post("/api/admin/editVehOrder", {
    token: token,
    orderno: orderno,
    ebusiness: ebusiness,
    license: license,
    licenseType: licenseType,
    vehType: vehType,
    vehUse: vehUse,
    joinDate: joinDate,
    joinTime: joinTime,
    money: money,
    contact: contact,
    phone: phone,
    driverphone: driverphone,
  });
};

// 删除车证订单
export const delVehOrder = (token, orderno) => {
  return axiosInstance.post("/api/admin/delVehOrder", {
    token: token,
    orderno: orderno,
  });
};

// 获取信用租配置信息
export const getCreditConfig = (token) => {
  return axiosInstance.post("/api/admin/getCreditConfig", {
    token: token,
  });
};

// 新增信用租配置信息
export const addCreditConfig = (
  token,
  name,
  lowtime,
  hightime,
  type,
  money
) => {
  return axiosInstance.post("/api/admin/addCreditConfig", {
    token: token,
    name: name,
    lowtime: lowtime,
    hightime: hightime,
    type: type,
    money: money,
  });
};

// 修改信用租配置信息
export const updateCreditConfig = (
  token,
  id,
  name,
  lowtime,
  hightime,
  type,
  money
) => {
  return axiosInstance.post("/api/admin/updateCreditConfig", {
    token: token,
    id: id,
    name: name,
    lowtime: lowtime,
    hightime: hightime,
    type: type,
    money: money,
  });
};

// 删除信用租配置信息
export const delCreditConfig = (token, id) => {
  return axiosInstance.post("/api/admin/delCreditConfig", {
    token: token,
    id: id,
  });
};

// 获取所有员工的姓名与电话
export const getEmployees = () => {
  return axiosInstance.post("/api/admin/getEmployees", {});
};

// 批量添加负责员工
export const addEmployeesForVid = (token, vid, list) => {
  return axiosInstance.post("/api/admin/addEmployeesForVid", {
    token: token,
    vid: vid,
    list: list,
  });
};

// 表格导出车证订单
export const exportVehOrderExcel = () => {
  return axiosInstance.post("/api/admin/exportVehOrderExcel", {});
};

// 获取机力分类
export const getMacType = (token) => {
  return axiosInstance.post("/api/admin/getMacType", {
    token: token,
  });
};

// 添加机力分类
export const addMacType = (token, name) => {
  return axiosInstance.post("/api/admin/addMacType", {
    token: token,
    name: name,
  });
};

// 编辑机力分类
export const editMacType = (token, id, name) => {
  return axiosInstance.post("/api/admin/editMacType", {
    token: token,
    id: id,
    name: name,
  });
};

// 删除机力分类
export const delMacType = (token, id) => {
  return axiosInstance.post("/api/admin/delMacType", {
    token: token,
    id: id,
  });
};

// 通过机力分类mid查看所有机力产品
export const getMacProductForMid = (token, mid) => {
  return axiosInstance.post("/api/admin/getMacProductForMid", {
    token: token,
    mid: mid,
  });
};

// 新增机力产品
export const addMacProductForMid = (token, mid, name, load) => {
  return axiosInstance.post("/api/admin/addMacProductForMid", {
    token: token,
    mid: mid,
    name: name,
    load: load,
  });
};

// 编辑机力产品
export const editMacProduct = (token, id, mid, name, load) => {
  return axiosInstance.post("/api/admin/editMacProduct", {
    token: token,
    id: id,
    mid: mid,
    name: name,
    load: load,
  });
};

// 删除机力产品
export const delMacProduct = (token, id) => {
  return axiosInstance.post("/api/admin/delMacProduct", {
    token: token,
    id: id,
  });
};

// 查询车辆进出表
export const getVehEntry = (token, page, list) => {
  return axiosInstance.post("/api/admin/getVehEntry", {
    token: token,
    page: page,
    list: list,
  });
};
