<template>
  <div>
    <el-button
      @click="addDialogVisible = true"
      type="success"
      style="margin: 0px 0px 10px 10px"
      >新增展会</el-button
    >
    <!-- 数据展示表格 -->
    <el-table
      :data="tableData"
      v-loading="loading"
      border
      stripe
      style="width: 100%"
    >
      <!-- <el-table-column prop="id" label="id" width="50"> </el-table-column>
      <el-table-column prop="eid" label="展会id"> </el-table-column> -->
      <el-table-column prop="value" label="展会名称" sortable>
      </el-table-column>
      <el-table-column prop="is_show" label="是否展示" sortable>
        <template slot-scope="scope">
          <el-tag v-if="scope.row.is_show === '1'" type="success"
            >展示中</el-tag
          >
          <el-tag v-else type="danger">未展示</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="money" label="出入场费用"> </el-table-column>
      <el-table-column prop="lengthValue" label="长度超限阈值">
      </el-table-column>
      <el-table-column prop="widthValue" label="宽度超限阈值">
      </el-table-column>
      <el-table-column prop="heightValue" label="高度超限阈值">
      </el-table-column>
      <el-table-column prop="weightValue" label="重量超限阈值">
      </el-table-column>
      <el-table-column prop="excursionLimits" label="超限叠加倍数">
      </el-table-column>
      <el-table-column prop="manageMoney" label="管理费用"> </el-table-column>
      <el-table-column prop="warhouseMoney" label="仓储费用"> </el-table-column>
      <el-table-column prop="emptyBoxMoney" label="空箱费用"> </el-table-column>
      <el-table-column prop="vehLicenseMoney" label="车证办理费用">
      </el-table-column>
      <el-table-column prop="vehLicenseDeposit" label="车证办理押金">
      </el-table-column>
      <el-table-column prop="timeLimit" label="展会内超时时限">
      </el-table-column>
      <el-table-column prop="timeOutPrice" label="超时扣除金额">
      </el-table-column>
      <el-table-column prop="createtime" label="创建日期">
        <template slot-scope="scope">{{
          formatDate(scope.row.createtime)
        }}</template>
      </el-table-column>
      <el-table-column prop="updatetime" label="修改日期">
        <template slot-scope="scope">
          <span v-if="scope.row.updatetime">{{
            formatDate(scope.row.updatetime)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="340">
        <template slot-scope="scope">
          <el-button
            @click="openChooseEhallDialog(scope.row.eid)"
            type="success"
            >查看展会负责员工</el-button
          >
          <el-button type="primary" @click="openEditDialog(scope.row)"
            >修改</el-button
          >
          <el-popconfirm
            title="确定删除吗？"
            @confirm="delEnames(scope.row.id)"
          >
            <el-button type="danger" slot="reference" style="margin-left: 10px"
              >删除</el-button
            >
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <!-- 修改菜单dialog -->
    <el-dialog title="修改菜单" :visible.sync="dialogVisible" width="70%">
      <el-row>
        <el-col :span="8" :offset="0">
          <el-form label-position="left" inline>
            <el-form-item label="请输入菜单名称">
              <el-input v-model="updateLabel"></el-input>
            </el-form-item>
            <el-form-item label="请输入出入展馆价格">
              <el-input v-model="updateMoney"></el-input>
            </el-form-item>
            <el-form-item label="请输入长度超限阈值">
              <el-input v-model="updateLengthValue"></el-input>
            </el-form-item>
            <el-form-item label="请输入宽度超限阈值">
              <el-input v-model="updateWidthValue"></el-input>
            </el-form-item>
            <el-form-item label="请输入高度超限阈值">
              <el-input v-model="updateHeightValue"></el-input>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="8" :offset="0">
          <el-form label-position="left" inline>
            <el-form-item label="请输入重量超限阈值">
              <el-input v-model="updateWeightValue"></el-input>
            </el-form-item>
            <el-form-item label="请输入超限叠加倍数">
              <el-input v-model="updateExcursionLimits"></el-input>
            </el-form-item>
            <el-form-item label="请输入管理费用">
              <el-input v-model="updateManageMoney"></el-input>
            </el-form-item>
            <el-form-item label="请输入仓储费用">
              <el-input v-model="updateWarhouseMoney"></el-input>
            </el-form-item>
            <el-form-item label="请输入空箱费用">
              <el-input v-model="updateEmptyBoxMoney"></el-input>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="8" :offset="0">
          <el-form label-position="left" inline>
            <el-form-item label="请选择是否展示">
              <br />
              <el-radio-group v-model="updateIsShow">
                <el-radio :label="0">不展示</el-radio>
                <el-radio :label="1">展示</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="请输入车证费用(元)">
              <el-input v-model="updateVehLicenseMoney"></el-input>
            </el-form-item>
            <el-form-item label="请输入车证押金(元)">
              <el-input v-model="updateVehLicenseDeposit"></el-input>
            </el-form-item>
            <el-form-item label="请输入展馆车辆时限(分钟)">
              <el-input v-model="updateTimeLimit"></el-input>
            </el-form-item>
            <el-form-item label="请输入超时扣除金额(元)">
              <el-input v-model="updateTimeOutPrice"></el-input>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editEnames()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 添加菜单dialog -->
    <el-dialog title="添加菜单" :visible.sync="addDialogVisible" width="70%">
      <el-row>
        <el-col :span="8" :offset="0">
          <el-form label-position="left" inline>
            <el-form-item label="请输入菜单名称">
              <el-input v-model="addLabel"></el-input>
            </el-form-item>
            <el-form-item label="请输入出入展馆价格">
              <el-input v-model="addMoney"></el-input>
            </el-form-item>
            <el-form-item label="请输入长度超限阈值">
              <el-input v-model="addLengthValue"></el-input>
            </el-form-item>
            <el-form-item label="请输入宽度超限阈值">
              <el-input v-model="addWidthValue"></el-input>
            </el-form-item>
            <el-form-item label="请输入高度超限阈值">
              <el-input v-model="addHeightValue"></el-input>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="8" :offset="0">
          <el-form label-position="left" inline>
            <el-form-item label="请输入重量超限阈值">
              <el-input v-model="addWeightValue"></el-input>
            </el-form-item>
            <el-form-item label="请输入超限叠加倍数">
              <el-input v-model="addExcursionLimits"></el-input>
            </el-form-item>
            <el-form-item label="请输入管理费用">
              <el-input v-model="addManageMoney"></el-input>
            </el-form-item>
            <el-form-item label="请输入仓储费用">
              <el-input v-model="addWarhouseMoney"></el-input>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="8" :offset="0">
          <el-form label-position="left" inline>
            <el-form-item label="请输入空箱费用">
              <el-input v-model="addEmptyBoxMoney"></el-input>
            </el-form-item>
            <el-form-item label="请输入车证费用(元)">
              <el-input v-model="addVehLicenseMoney"></el-input>
            </el-form-item>
            <el-form-item label="请输入车证押金(元)">
              <el-input v-model="addVehLicenseDeposit"></el-input>
            </el-form-item>
            <el-form-item label="请输入展馆车辆时限(分钟)">
              <el-input v-model="addTimeLimit"></el-input>
            </el-form-item>
            <el-form-item label="请输入超时扣除金额(元)">
              <el-input v-model="addTimeOutPrice"></el-input>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addEnames()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 选择展馆dialog -->
    <el-dialog title="选择展馆" :visible.sync="chooseEhallDialog" width="30%">
      <el-table
        :data="EhallData"
        v-loading="cloading"
        stripe
        style="width: 100%"
      >
        <!-- <el-table-column prop="id" label="展馆名称"> </el-table-column> -->
        <el-table-column prop="value" label="展馆名称"> </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button @click="openEpDialog(scope.row.id)" type="primary"
              >查看负责员工</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="chooseEhallDialog = false">取 消</el-button>
        <el-button type="primary" @click="chooseEhallDialog = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 查看负责员工dialog -->
    <el-dialog title="查看负责员工" :visible.sync="epDialog" width="80%">
      <el-button type="success" @click="addEpDialog = true"
        >批量添加负责员工</el-button
      >
      <el-table :data="epData" style="width: 100%">
        <el-table-column prop="id" label="id"> </el-table-column>
        <el-table-column prop="vid" label="归属展馆id"> </el-table-column>
        <el-table-column prop="employee_name" label="负责员工姓名">
        </el-table-column>
        <el-table-column prop="employee_phone" label="负责员工电话">
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-popconfirm
              title="确定删除该员工吗？"
              @confirm="dropEmployeeForVids(scope.row.id)"
            >
              <el-button type="text" slot="reference">删除</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="epDialog = false">取 消</el-button>
        <el-button type="primary" @click="epDialog = false">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 添加负责员工dialog -->
    <el-dialog title="添加负责员工" :visible.sync="addEpDialog1" width="30%">
      <el-form>
        <el-form-item label="负责员工电话">
          <el-input
            v-model="addEp.phone"
            placeholder="请输入员工电话"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addEpDialog = false">取 消</el-button>
        <el-button type="primary" @click="addEmployeeForVids()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 批量添加负责员工 -->
    <el-dialog title="批量添加负责员工" :visible.sync="addEpDialog" width="30%">
      <span>{{ epList }}</span>
      <el-form>
        <el-form-item label="选择负责员工">
          <br />
          <el-checkbox v-model="checkAll" @change="handleCheckAllChange">
            全选
          </el-checkbox>

          <!-- 员工列表复选框组 -->
          <el-checkbox-group
            v-model="selectedItems"
            @change="handleSelectionChange"
          >
            <el-checkbox
              v-for="item in epList"
              :key="item.id"
              :label="item.phone"
            >
              {{ item.nickname + " " + item.phone }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addEpDialog = false">取 消</el-button>
        <el-button type="primary" @click="addEmployeesForVids()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  addEname,
  editEname,
  getEnameList,
  delEname,
  getEhallForEid,
  getEmployeeForVid,
  addEmployeeForVid,
  getEmployees,
  addEmployeesForVid,
} from "@/api/api";
import moment from "moment";

export default {
  data() {
    return {
      checkAll: false, // 全选复选框状态
      tableData: [],
      loading: false,
      updateid: "",
      updateLabel: "",
      updateValue: "",
      updateMoney: "",
      updateLengthValue: "",
      updateWidthValue: "",
      updateHeightValue: "",
      updateWeightValue: "",
      updateExcursionLimits: "",
      updateManageMoney: "",
      updateWarhouseMoney: "",
      updateEmptyBoxMoney: "",
      updateVehLicenseMoney: "",
      updateVehLicenseDeposit: "",
      updateTimeLimit: "",
      updateTimeOutPrice: "",
      updateIsShow: "",
      addLabel: "",
      addValue: "",
      addMoney: "",
      addLengthValue: "",
      addWidthValue: "",
      addHeightValue: "",
      addWeightValue: "",
      addExcursionLimits: "",
      addManageMoney: "",
      addWarhouseMoney: "",
      addEmptyBoxMoney: "",
      addVehLicenseMoney: "",
      addVehLicenseDeposit: "",
      addTimeLimit: "",
      addTimeOutPrice: "",
      dialogVisible: false,
      addDialogVisible: false,
      chooseEhallDialog: false,
      EhallData: [],
      cloading: false,
      epDialog: false,
      addEpDialog: false,
      vid: "",
      epData: [],
      addEp: [],
      epList: [],
      selectedItems: [],
    };
  },
  methods: {
    handleCheckAllChange(checked) {
      if (checked) {
        // 全选时，将所有的手机号添加到 selectedItems
        this.selectedItems = this.epList.map((item) => item.phone);
      } else {
        // 取消全选时，清空 selectedItems
        this.selectedItems = [];
      }
    },
    // 处理复选框组的变更，更新全选状态
    handleSelectionChange(val) {
      this.checkAll = val.length === this.epList.length;
    },
    getEmployee() {
      getEmployees().then((res) => {
        if (res.data.code === 200) {
          this.epList = res.data.data;
        } else {
          this.$message.error("获取员工列表失败！");
          console.log(res);
        }
      });
    },
    addEmployeeForVids() {
      const loading = this.$loading({
        lock: true,
        text: "正在加载",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const token = localStorage.getItem("token");
      addEmployeeForVid(token, this.vid, this.addEp.phone).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("添加成功！");
          this.addEpDialog = false;
          this.epDialog = false;
          this.getEmployeeForVids();
          loading.close();
        } else {
          this.$message.error(res.data.msg);
          loading.close();
        }
      });
    },
    addEmployeesForVids() {
      const loading = this.$loading({
        lock: true,
        text: "正在添加",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const token = localStorage.getItem("token");
      addEmployeesForVid(token, this.vid, this.selectedItems).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("添加成功！");
          this.addEpDialog = false;
          this.epDialog = false;
          this.getEmployeeForVids();
          loading.close();
        } else {
          this.$message.error(res.data.msg);
          loading.close();
        }
      });
    },
    getEmployeeForVids() {
      const loading = this.$loading({
        lock: true,
        text: "正在加载",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const token = localStorage.getItem("token");
      getEmployeeForVid(token, this.vid).then((res) => {
        if (res.data.code === 200) {
          this.epData = res.data.data;
          loading.close();
        } else {
          this.$message.error(res.data.msg);
          loading.close();
        }
      });
    },
    openEpDialog(vid) {
      this.vid = vid;
      this.getEmployeeForVids();
      this.epDialog = true;
    },
    openChooseEhallDialog(eid) {
      this.getEhallForEids(eid);
      this.chooseEhallDialog = true;
      this.cloading = true;
    },
    getEhallForEids(eid) {
      const loading = this.$loading({
        lock: true,
        text: "正在加载",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const token = localStorage.getItem("token");
      getEhallForEid(token, eid).then((res) => {
        if (res.data.code === 200) {
          this.EhallData = res.data.data;
          loading.close();
          this.cloading = false;
        } else {
          this.$message.error(res.data.msg);
          loading.close();
          this.cloading = false;
        }
      });
    },
    // 时间格式化
    formatDate(date) {
      return moment.unix(date).format("YYYY-MM-DD HH:mm:ss");
    },
    openEditDialog(row) {
      this.updateid = row.id;
      this.updateLabel = row.label;
      this.updateValue = row.value;
      this.updateMoney = row.money;
      this.updateLengthValue = row.lengthValue;
      this.updateWidthValue = row.widthValue;
      this.updateHeightValue = row.heightValue;
      this.updateWeightValue = row.weightValue;
      this.updateExcursionLimits = row.excursionLimits;
      this.updateManageMoney = row.manageMoney;
      this.updateWarhouseMoney = row.warhouseMoney;
      this.updateEmptyBoxMoney = row.emptyBoxMoney;
      this.updateVehLicenseMoney = row.vehLicenseMoney;
      this.updateVehLicenseDeposit = row.vehLicenseDeposit;
      this.updateTimeLimit = row.timeLimit;
      this.updateTimeOutPrice = row.timeOutPrice;
      this.updateIsShow = row.is_show;
      this.dialogVisible = true;
    },
    getEnameLists() {
      this.loading = true;
      getEnameList().then((res) => {
        if (res.data.code === 200) {
          this.tableData = res.data.data;
          this.loading = false;
        } else {
          this.$message.error(res.data.msg);
          this.loading = false;
        }
      });
    },
    addEnames() {
      const loading = this.$loading({
        lock: true,
        text: "正在加载",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      this.addValue = this.addLabel;
      addEname(
        this.addLabel,
        this.addValue,
        this.addMoney,
        this.addLengthValue,
        this.addWidthValue,
        this.addHeightValue,
        this.addWeightValue,
        this.addExcursionLimits,
        this.addManageMoney,
        this.addWarhouseMoney,
        this.addEmptyBoxMoney,
        this.addVehLicenseMoney,
        this.addVehLicenseDeposit,
        this.addTimeLimit,
        this.addTimeOutPrice
      ).then((res) => {
        if (res.data.code === 200) {
          this.getEnameLists();
          this.$message.success("添加成功！");
          this.addDialogVisible = false;
          loading.close();
        } else {
          this.$message.error(res.data.msg);
          loading.close();
        }
      });
    },
    editEnames() {
      const loading = this.$loading({
        lock: true,
        text: "正在加载",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      this.updateValue = this.updateLabel;
      editEname(
        this.updateid,
        this.updateLabel,
        this.updateValue,
        this.updateMoney,
        this.updateLengthValue,
        this.updateWidthValue,
        this.updateHeightValue,
        this.updateWeightValue,
        this.updateExcursionLimits,
        this.updateManageMoney,
        this.updateWarhouseMoney,
        this.updateIsShow,
        this.updateEmptyBoxMoney,
        this.updateVehLicenseMoney,
        this.updateVehLicenseDeposit,
        this.updateTimeLimit,
        this.updateTimeOutPrice
      ).then((res) => {
        if (res.data.code === 200) {
          this.getEnameLists();
          this.dialogVisible = false;
          this.$message.success("修改成功！");
          loading.close();
        } else {
          this.$message.error(res.data.msg);
          loading.close();
        }
      });
    },
    delEnames(id) {
      const loading = this.$loading({
        lock: true,
        text: "正在加载",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      delEname(id).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("删除成功");
          this.getEnameLists();
          loading.close();
        } else {
          this.$message.error(res.data.msg);
          loading.close();
        }
      });
    },
  },
  created() {
    this.getEnameLists();
    this.getEmployee();
  },
};
</script>

<style></style>
