<template>
  <div>
    <div style="margin: 0px 0px 10px 10px">
      <el-button type="success" @click="dialogVisible = true"
        >添加员工</el-button
      >
    </div>
    <!-- 数据展示表格 -->
    <el-table
      :data="tableData"
      v-loading="loading"
      border
      stripe
      style="width: 100%"
    >
      <el-table-column type="selection"> </el-table-column>
      <el-table-column prop="id" label="id"> </el-table-column>
      <el-table-column prop="nickname" label="员工姓名"> </el-table-column>
      <el-table-column prop="username" label="用户名"> </el-table-column>
      <el-table-column prop="phone" label="联系方式"> </el-table-column>
      <el-table-column prop="create_time" label="创建时间">
        <template slot-scope="scope">
          {{ formatDate(scope.row.create_time) }}
        </template>
      </el-table-column>
      <el-table-column prop="avatar" label="头像"> </el-table-column>
      <el-table-column label="操作" width="330">
        <template slot-scope="scope">
          <el-button type="warning" @click="openCheckDialog(scope.row)"
            >允许查看的展会</el-button
          >
          <el-button type="primary" @click="openEditDialog(scope.row)"
            >编辑</el-button
          >
          <el-popconfirm
            title="数据删除后无法恢复！确定删除吗？"
            @confirm="deleteEmployees(scope.row.id)"
          >
            <el-button type="danger" slot="reference" style="margin-left: 10px"
              >删除</el-button
            >
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <el-row :gutter="20" style="margin: 0 20px 0 20px">
      <el-col :span="12" :offset="0" style="line-height: 60px">
        <span>共 {{ total }} 页</span>
        <span style="margin-left: 20px">显示页数</span>
        <el-input-number
          v-model="list"
          controls-position="right"
          @change="GetRechargeLists"
          :min="1"
          :max="100"
          style="margin-left: 10px"
        ></el-input-number>
      </el-col>
      <el-col :span="12" :offset="0">
        <div
          style="
            display: flex;
            justify-content: right;
            align-items: right;
            margin-top: 20px;
          "
        >
          <el-button
            round
            style="padding: 10px"
            @click="changePage(currentPage - 1)"
            :disabled="currentPage === 1"
          >
            <i class="el-icon-arrow-left"></i>
          </el-button>
          <div v-for="page in total" :key="page" style="margin: 0 5px">
            <div
              @click="changePage(page)"
              :class="{ 'active-page': currentPage === page }"
              class="page-button"
            >
              {{ page }}
            </div>
          </div>
          <el-button
            round
            style="padding: 10px"
            @click="changePage(currentPage + 1)"
            :disabled="currentPage === total"
          >
            <i class="el-icon-arrow-right"></i>
          </el-button>
        </div>
      </el-col>
    </el-row>
    <!-- 添加员工dialog -->
    <el-dialog title="添加员工" :visible.sync="dialogVisible" width="60%">
      <el-row gutter="20">
        <el-col :span="12" :offset="0">
          <el-form>
            <el-form-item label="员工姓名">
              <el-input v-model="name"></el-input>
            </el-form-item>
            <el-form-item label="员工登录用户名">
              <el-input v-model="username"></el-input>
            </el-form-item>
            <el-form-item label="员工登录密码">
              <el-input v-model="password"></el-input>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="12" :offset="0">
          <el-form>
            <el-form-item label="员工手机号码">
              <el-input v-model="phone"></el-input>
            </el-form-item>
            <el-form-item label="选择可查看展会">
              <el-select
                v-model="ename"
                style="width: 100%"
                placeholder="请选择对应展会"
                @change="getEhallForNames()"
              >
                <el-option
                  v-for="item in enames"
                  :key="item.id"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <el-checkbox
                v-if="ename"
                v-model="checkAll"
                @change="handleCheckAllChange"
                >全选</el-checkbox
              >
              <!-- 复选框组 -->
              <el-checkbox-group v-model="ehall" @change="handleCheckboxChange">
                <el-checkbox
                  v-for="item in ehalls"
                  :key="item.id"
                  :label="item.value"
                >
                  {{ item.label }}
                </el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addEmployees()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 编辑员工dialog -->
    <el-dialog title="编辑员工" :visible.sync="editDialogvisible" width="60%">
      <el-row :gutter="20">
        <el-col :span="12" :offset="0">
          <el-form>
            <el-form-item label="员工姓名">
              <el-input v-model="editName"></el-input>
            </el-form-item>
            <el-form-item label="员工登录用户名">
              <el-input v-model="editUsername"></el-input>
            </el-form-item>
            <el-form-item label="员工登录密码">
              <el-input v-model="editPassword"></el-input>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="12" :offset="0">
          <el-form>
            <el-form-item label="员工头像">
              <el-input v-model="editAvatar"></el-input>
            </el-form-item>
            <el-form-item label="员工电话号码">
              <el-input v-model="editPhone"></el-input>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>

      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogvisible = false">取 消</el-button>
        <el-button type="primary" @click="editEmployees()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 数据查看 -->
    <el-dialog title="数据查看" :visible.sync="rowVisible" width="60%">
      <el-table :data="rowData" stripe style="width: 100%">
        <el-table-column prop="id" label="id"> </el-table-column>
        <el-table-column prop="epid" label="员工ID"> </el-table-column>
        <el-table-column prop="ename" label="展会名称">
          <template slot-scope="scope">
            <span v-if="scope.row.ename === 'all'">允许查看所有展会</span>
            <span v-else>{{ scope.row.ename }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="permission" label="允许查看的展馆">
          <template slot-scope="scope">
            <span v-if="scope.row.permission === 'all'">允许查看所有展馆</span>
            <span v-else>{{ scope.row.permission }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-popconfirm
              title="确定删除吗？"
              @confirm="delAllowChecks(scope.row.id)"
            >
              <el-button slot="reference" type="text">删除</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-popconfirm
          title="您确定要允许查看所有展会展馆吗？"
          @confirm="addAllowCheckAlls()"
        >
          <el-button type="success" slot="reference" style="margin-right: 10px"
            >允许查看所有展会展馆</el-button
          >
        </el-popconfirm>
        <el-button type="warning" @click="allowDialog = true"
          >添加允许查看的展馆</el-button
        >
        <el-button @click="rowVisible = false">取 消</el-button>
        <el-button type="primary" @click="rowVisible = false">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="添加允许查看的展馆"
      :visible.sync="allowDialog"
      width="30%"
    >
      <p>员工id：{{ addRow.id }}</p>
      <p>员工姓名：{{ addRow.nickname }}</p>
      <p>员工电话：{{ addRow.phone }}</p>
      <el-form>
        <el-form-item label="选择可查看展会">
          <el-select
            v-model="ename"
            style="width: 100%"
            placeholder="请选择对应展会"
            @change="getEhallForNames()"
          >
            <el-option
              v-for="item in enames"
              :key="item.id"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-checkbox
            v-if="ename"
            v-model="checkAll"
            @change="handleCheckAllChange"
            >全选</el-checkbox
          >
          <!-- 复选框组 -->
          <el-checkbox-group v-model="ehall" @change="handleCheckboxChange">
            <el-checkbox
              v-for="item in ehalls"
              :key="item.id"
              :label="item.value"
            >
              {{ item.label }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="allowDialog = false">取 消</el-button>
        <el-button type="primary" @click="addAllowChecks()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getEmployeeList,
  addEmployee,
  editEmployee,
  getEmployeeInfo,
  deleteEmployee,
  getEnameList,
  getEhallForName,
  delAllowCheck,
  addAllowCheck,
  addAllowCheckAll,
} from "@/api/api";
import moment from "moment";

export default {
  data() {
    return {
      page: 1,
      list: 5,
      total: 0, // 总页数
      currentPage: 1,
      tableData: [],
      loading: false,
      username: "",
      password: "",
      name: "",
      editId: "",
      editUsername: "",
      editPassword: "",
      editName: "",
      editAvatar: "",
      editPhone: "",
      dialogVisible: false,
      editDialogvisible: false,
      phone: "",
      enames: [],
      ename: "",
      ehalls: [],
      ehall: [],
      rowData: [],
      rowVisible: false,
      allowDialog: false,
      addRow: [],
      checkAll: false, // 全选状态
    };
  },
  methods: {
    // 处理全选
    handleCheckAllChange(val) {
      if (val) {
        // 如果全选，设置 ehall 为所有展馆的 value
        this.ehall = this.ehalls.map((item) => item.value);
      } else {
        // 如果取消全选，清空选中的展馆
        this.ehall = [];
      }
    },
    // 处理单个复选框变化
    handleCheckboxChange() {
      // 如果当前选中的数量和展馆总数相等，设置 checkAll 为 true
      this.checkAll = this.ehall.length === this.ehalls.length;
    },
    // openAllowDialog(row) {
    //   this.allowDialog = true;
    // },
    openCheckDialog(row) {
      this.rowData = row.allow;
      this.addRow = row;
      this.rowVisible = true;
    },
    getEhallForNames() {
      const loading = this.$loading({
        lock: true,
        text: "正在加载",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      getEhallForName(this.ename)
        .then((res) => {
          if (res.data.code === 200) {
            this.ehalls = res.data.data;
            loading.close();
          } else {
            this.$message.error(res.data.msg);
            loading.close();
          }
        })
        .catch((res) => {
          this.$message.error(res.msg);
          loading.close();
        });
    },
    getEnameLists() {
      getEnameList().then((res) => {
        if (res.data.code === 200) {
          this.enames = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    // 时间格式化
    formatDate(date) {
      return moment.unix(date).format("YYYY-MM-DD HH:mm:ss");
    },
    changePage(page) {
      if (page < 1 || page > this.total) return;
      this.currentPage = page;
      this.page = page;
      this.getEmployeeLists();
    },
    getEmployeeLists() {
      this.loading = true;
      const token = localStorage.getItem("token");
      getEmployeeList(token, this.page, this.list).then((res) => {
        if (res.data.code === 200) {
          this.tableData = res.data.data.data;
          this.total = res.data.data.total;
          this.loading = false;
        } else {
          this.$message.error(res.data.msg);
          this.loading = false;
        }
      });
    },
    getEmployeeInfos() {
      const token = localStorage.getItem("token");
      getEmployeeInfo(token, this.editId).then((res) => {
        if (res.data.code === 200) {
          this.editUsername = res.data.data.username;
          // this.editPassword = res.data.data.password;
          this.editName = res.data.data.nickname;
          this.editAvatar = res.data.data.avatar;
          this.editPhone = res.data.data.phone;
        } else {
          this.$message.error("获取员工信息失败，" + res.data.msg);
        }
      });
    },
    addEmployees() {
      const token = localStorage.getItem("token");
      addEmployee(
        token,
        this.username,
        this.password,
        this.name,
        this.phone,
        this.ename,
        this.ehall
      ).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("添加成功");
          this.getEmployeeLists();
          this.dialogVisible = false;
        } else {
          this.$message.error(res.data.msg);
          this.dialogVisible = false;
        }
      });
    },
    openEditDialog(row) {
      this.editId = row.id;
      this.getEmployeeInfos();
      // this.editUsername = row.username;
      // this.editPassword = row.password;
      // this.editName = row.name;
      // this.editAvatar = row.avatar;
      this.editDialogvisible = true;
    },
    editEmployees() {
      const token = localStorage.getItem("token");
      editEmployee(
        token,
        this.editId,
        this.editUsername,
        this.editPassword,
        this.editName,
        this.editAvatar,
        this.editPhone
      ).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("编辑成功");
          this.getEmployeeLists();
          this.editDialogvisible = false;
        } else {
          this.$message.error(res.data.msg);
          this.editDialogvisible = false;
        }
      });
    },
    deleteEmployees(id) {
      const token = localStorage.getItem("token");
      deleteEmployee(token, id).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("删除成功");
          this.getEmployeeLists();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    delAllowChecks(id) {
      const loading = this.$loading({
        lock: true,
        text: "正在加载",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const token = localStorage.getItem("token");
      delAllowCheck(token, id)
        .then((res) => {
          if (res.data.code === 200) {
            this.$message.success("删除成功");
            this.getEmployeeLists();
            this.rowVisible = false;
            this.allowDialog = false;
            loading.close();
          } else {
            this.$message.error(res.data.msg);
            loading.close();
          }
        })
        .catch((res) => {
          this.$message.error(res.msg);
          loading.close();
        });
    },
    addAllowChecks() {
      const loading = this.$loading({
        lock: true,
        text: "正在加载",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const token = localStorage.getItem("token");
      addAllowCheck(token, this.addRow.id, this.ename, this.ehall)
        .then((res) => {
          if (res.data.code === 200) {
            this.$message.success("添加成功");
            this.getEmployeeLists();
            this.rowVisible = false;
            this.allowDialog = false;
            loading.close();
          } else {
            this.$message.error(res.data.msg);
            loading.close();
          }
        })
        .catch((res) => {
          this.$message.error(res.data.msg);
          loading.close();
        });
    },
    addAllowCheckAlls() {
      const loading = this.$loading({
        lock: true,
        text: "正在加载",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const token = localStorage.getItem("token");
      addAllowCheckAll(token, this.addRow.id)
        .then((res) => {
          if (res.data.code === 200) {
            this.$message.success("修改成功");
            this.getEmployeeLists();
            this.rowVisible = false;
            this.allowDialog = false;
            loading.close();
          } else {
            this.$message.error(res.data.msg);
            loading.close();
          }
        })
        .catch((res) => {
          this.$message.error(res.msg);
          loading.close();
        });
    },
  },
  created() {
    this.getEmployeeLists();
    this.getEnameLists();
  },
};
</script>
