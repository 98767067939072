<template>
  <div>
    <div style="margin: 0 0 10px 10px">
      <a href="https://api.jq56.com/api/admin/exportRentExcel" target="_blank">
        <el-button type="primary">下载租赁订单表格</el-button>
      </a>
    </div>
    <!-- 数据展示表格 -->
    <el-table
      :data="rentOrderList"
      v-loading="loading"
      border
      stripe
      style="width: 100%"
    >
      <el-table-column type="expand">
        <template slot-scope="props">
          <el-form
            label-position="left"
            inline
            class="demo-table-expand"
            label-width="120px"
            style="margin-left: 20px"
          >
            <el-form-item label="下单用户id">
              <span>{{ props.row.userid }}</span>
            </el-form-item>
            <el-form-item label="下单联系人">
              <span>{{ props.row.contact }}</span>
            </el-form-item>
            <el-form-item label="联系电话">
              <span>{{ props.row.phone }}</span>
            </el-form-item>
            <el-form-item label="归属展会">
              <span>{{ props.row.ename }}</span>
            </el-form-item>
            <el-form-item label="展商名称">
              <span>{{ props.row.ebusiness }}</span>
            </el-form-item>
            <el-form-item label="展商位置">
              <span>{{ props.row.ehall }}展馆{{ props.row.eloc }}号位</span>
            </el-form-item>
            <el-form-item label="预计使用时间">
              <span>{{ props.row.usetime }}</span>
            </el-form-item>
            <el-form-item label="租赁设备数量">
              <span>{{ props.row.count }}台/辆</span>
            </el-form-item>
            <el-form-item label="订单是否付款">
              <el-tag v-if="props.row.is_pay == 1" type="success"
                >已付款</el-tag
              >
              <el-tag v-else type="danger">未付款</el-tag>
            </el-form-item>
            <el-form-item label="是否被员工接受">
              <el-tag v-if="props.row.is_accept == 1" type="success"
                >已接受</el-tag
              >
              <el-tag v-else type="danger">未接受</el-tag>
            </el-form-item>
            <el-form-item v-if="props.row.is_accept == 1" label="接受处理员工">
              <span>{{ props.row.employee_name }}</span>
            </el-form-item>
            <el-form-item v-if="props.row.is_accept == 1" label="处理员工电话">
              <span>{{ props.row.employee_phone }}</span>
            </el-form-item>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column type="selection"> </el-table-column>
      <el-table-column prop="id" label="id"> </el-table-column>
      <!-- <el-table-column prop="userid" label="下单用户id"> </el-table-column> -->
      <el-table-column prop="orderid" label="租赁订单号"> </el-table-column>
      <el-table-column prop="product" label="租赁设备"> </el-table-column>
      <el-table-column prop="load" label="额定荷载重量"> </el-table-column>
      <!-- <el-table-column prop="ebusiness" label="展商名称"> </el-table-column> -->
      <!-- <el-table-column prop="ename" label="归属展会"> </el-table-column> -->
      <!-- <el-table-column label="展商位置">
        <template slot-scope="scope">
          <span>{{ scope.row.ehall }}展馆{{ scope.row.eloc }}号位</span>
        </template>
      </el-table-column> -->
      <!-- <el-table-column prop="usetime" label="预计使用时间"> </el-table-column> -->
      <!-- <el-table-column prop="count" label="租赁设备数量"> </el-table-column> -->
      <el-table-column prop="money" label="订单价格"> </el-table-column>
      <el-table-column prop="memo" label="订单备注"> </el-table-column>
      <el-table-column prop="is_pay" label="是否支付">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.is_pay == 1" type="success">已支付</el-tag>
          <el-tag v-else type="danger">未支付</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="is_accept" label="是否接受处理">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.is_accept == 1" type="success">已接受</el-tag>
          <el-tag v-else type="danger">未接受</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="employee_name" label="处理员工"> </el-table-column>
      <el-table-column prop="employee_phone" label="员工电话">
      </el-table-column>
      <el-table-column label="操作" width="180">
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.is_pay === 0"
            type="primary"
            @click="openEditDialog(scope.row)"
            >编辑</el-button
          >
          <el-button v-if="scope.row.is_pay === 1" type="primary" disabled
            >编辑</el-button
          >
          <el-popconfirm
            v-if="scope.row.is_pay === 0"
            title="确定删除该订单吗？"
            @confirm="delRentOrders(scope.row.orderid)"
          >
            <el-button slot="reference" type="danger" style="margin-left: 10px"
              >删除</el-button
            >
          </el-popconfirm>
          <el-button v-else slot="reference" type="danger" disabled
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <el-row :gutter="20" style="margin: 0 20px 0 20px">
      <el-col :span="12" :offset="0" style="line-height: 60px">
        <span>共 {{ total }} 页</span>
        <span style="margin-left: 20px">显示页数</span>
        <el-input-number
          v-model="list"
          controls-position="right"
          @change="getRentOrder"
          :min="1"
          :max="100"
          style="margin-left: 10px"
        ></el-input-number>
      </el-col>
      <el-col :span="12" :offset="0">
        <div
          style="
            display: flex;
            justify-content: right;
            align-items: right;
            margin-top: 20px;
          "
        >
          <el-button
            round
            style="padding: 10px"
            @click="changePage(currentPage - 1)"
            :disabled="currentPage === 1"
          >
            <i class="el-icon-arrow-left"></i>
          </el-button>
          <div v-for="page in total" :key="page" style="margin: 0 5px">
            <div
              @click="changePage(page)"
              :class="{ 'active-page': currentPage === page }"
              class="page-button"
            >
              {{ page }}
            </div>
          </div>
          <el-button
            round
            style="padding: 10px"
            @click="changePage(currentPage + 1)"
            :disabled="currentPage === total"
          >
            <i class="el-icon-arrow-right"></i>
          </el-button>
        </div>
      </el-col>
    </el-row>
    <!-- 编辑弹窗 -->
    <el-dialog title="编辑订单" :visible.sync="dialogVisible" width="80%">
      <el-row :gutter="20">
        <el-col :span="12" :offset="0">
          <el-form label-width="100px">
            <el-form-item label="租赁设备">
              <el-input v-model="editOrderInfo.product"></el-input>
            </el-form-item>
            <el-form-item label="额定荷载">
              <el-input v-model="editOrderInfo.load"></el-input>
            </el-form-item>
            <el-form-item label="展会名称">
              <el-input v-model="editOrderInfo.ename"></el-input>
            </el-form-item>
            <el-form-item label="使用时间">
              <el-input v-model="editOrderInfo.usetime"></el-input>
              <span>(请严格按照格式修改 例如：1小时或1天)</span>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="12" :offset="0">
          <el-form label-width="100px">
            <el-form-item label="展馆号">
              <el-input v-model="editOrderInfo.ehall"></el-input>
            </el-form-item>
            <el-form-item label="展位号">
              <el-input v-model="editOrderInfo.eloc"></el-input>
            </el-form-item>
            <el-form-item label="设备数量">
              <el-input v-model="editOrderInfo.count"></el-input>
            </el-form-item>
            <el-form-item label="订单价格">
              <el-input v-model="editOrderInfo.money"></el-input>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editRentOrder()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { delRentOrder, editRentOrders, getRentOrders } from "@/api/api";
export default {
  data() {
    return {
      rentOrderList: [],
      loading: false,
      page: 1,
      list: 5,
      total: 0, // 总页数
      currentPage: 1,
      dialogVisible: false,
      editOrderInfo: [],
    };
  },
  methods: {
    openEditDialog(row) {
      this.editOrderInfo = row;
      this.dialogVisible = true;
    },
    getRentOrder() {
      this.loading = true;
      const token = localStorage.getItem("token");
      getRentOrders(token, this.page, this.list)
        .then((res) => {
          if (res.data.code === 200) {
            this.rentOrderList = res.data.data.data;
            this.total = res.data.data.total;
            this.loading = false;
          } else {
            this.$message.error(res.data.msg);
            this.loading = false;
          }
        })
        .catch((res) => {
          this.$message.error(res.data.msg);
          this.loading = false;
        });
    },
    editRentOrder() {
      const token = localStorage.getItem("token");
      editRentOrders(
        token,
        this.editOrderInfo.orderid,
        this.editOrderInfo.product,
        this.editOrderInfo.load,
        this.editOrderInfo.ename,
        this.editOrderInfo.usetime,
        this.editOrderInfo.ehall,
        this.editOrderInfo.eloc,
        this.editOrderInfo.count,
        this.editOrderInfo.money
      )
        .then((res) => {
          if (res.data.code === 200) {
            this.$message.success(res.data.msg);
            this.dialogVisible = false;
            this.getRentOrder();
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((res) => {
          this.$message.error(res.data.msg);
        });
    },
    delRentOrders(orderid) {
      const loading = this.$loading({
        lock: true,
        text: "正在删除中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const token = localStorage.getItem("token");
      delRentOrder(token, orderid)
        .then((res) => {
          if (res.data.code === 200) {
            this.$message.success(res.data.msg);
            this.getRentOrder();
            loading.close();
          } else {
            this.$message.error(res.data.msg);
            loading.close();
          }
        })
        .catch((res) => {
          this.$message.error(res.data.msg);
          loading.close();
        });
    },
    changePage(page) {
      if (page < 1 || page > this.total) return;
      this.currentPage = page;
      this.page = page;
      this.getRentOrder();
    },
  },
  created() {
    this.getRentOrder();
  },
};
</script>

<style>
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
</style>
